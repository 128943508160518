import { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import usersService from "./services/users";
import LoadingSpinner from "./components/LoadingSpinner";
// import SideMenu from "./screens/SideMenu";

function Root() {
  const location = useLocation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  // // 사이드 메뉴 미포함 경로
  // const noHeaderPaths = [
  //   "/",
  //   "/sign-up",
  //   "/sign-up-request",
  //   "/update-password",
  // ];
  // const headerFlag =
  //   !noHeaderPaths.includes(location.pathname) &&
  //   !location.pathname.startsWith("/caption");

  useEffect(() => {
    const shouldCheckLoginStatus = () =>
      !["/", "/login"].includes(location.pathname);

    if (!shouldCheckLoginStatus()) return setIsLoading(false);

    usersService.isLoggedIn().then((res) => {
      if (res.code !== 200 || !res.result?.loginFlag) {
        alert("세션이 만료되었습니다. 로그인 페이지로 이동합니다.");
        navigate("/", { replace: true });
      } else setIsLoading(false);
    });
  }, [location.pathname]);

  if (isLoading) return <LoadingSpinner />;

  return (
    <>
      {/* <Header /> */}
      {/* {headerFlag ? <SideMenu outlet={<Outlet />} /> : <Outlet />} */}
      <Outlet />
    </>
  );
}

export default Root;
