import { useNavigate } from "react-router-dom";

import { ReactComponent as LANGSA_LOGO } from "../assets/images/langsa-logo.svg";
import { ReactComponent as LANGSA_TEXT } from "../assets/images/langsa-text.svg";
import { ReactComponent as IconGlasses } from "../assets/images/icons/icon-glasses.svg";
import { ReactComponent as IconClose } from "../assets/images/icons/icon-close.svg";
import PopupMenu from "../components/PopupMenu/PopupMenu";
import { useEffect, useState } from "react";
import useSession from "../services/useSession";
import usersService from "../services/users";

// 다산 숨김
const isDasan = true;

export default function Main() {
  const navigate = useNavigate();
  const bgScreen = isDasan ? "bg-dasan-screen" : "bg-main-screen";
  const mainText = "안녕하세요 Langsa입니다! 새로운 AI 기반 검색 모드입니다 ✨";

  const { setSessionChatMsg, setSessionSetting } = useSession();
  const [isShowMenu, setIsShowMenu] = useState(false);
  const [text, setText] = useState("");

  const handleMenu = () => {
    setIsShowMenu((prev) => !prev);
  };

  const closeMenu = () => {
    setIsShowMenu(false);
  };

  const handleSearch = (e) => {
    setText(e.target.value);
  };

  const handleSearchClear = () => {
    setText("");
  };

  const onEnterKeyPress = () => {
    if (window.event.keyCode === 13) {
      onSearch();
    }
  };

  const onSearch = () => {
    if (text !== "") {
      setSessionChatMsg(text).then((setRes) => {
        console.log("setSessionChatMsg response >>>> ", setRes);
        if (setRes.code === 200) {
          console.log("text >>>> ", text);
          // 다산 숨김
          // window.location.href = "https://dev.langsa.ai/playground";
          window.location.href = "https://120dasan.langsa.ai/playground";
        }
      });
    }
  };

  useEffect(() => {
    if (isDasan) {
      usersService
        .login({ emailId: "dasan120@timbel.net", password: "dasan123#" })
        .then((data) => {
          const { code, result } = data;
          if (code === 200) {
            console.log("login success");
            setSessionSetting({
              workspace: result.personalWorkspaceId,
              knowledge: result.knowledgeId,
            });
          } else {
            console.log("login failed");
          }
        });
    }
  }, []);

  return (
    <div
      className={`flex flex-col w-screen h-screen ${bgScreen} bg-repeat bg-center bg-cover`}
    >
      <div className="flex flex-row w-full py-[30px] px-[30px]">
        <div className="flex w-full h-[70px] items-center justify-end space-x-[20px]">
          {!isDasan && (
            <>
              <div>
                <button
                  type="button"
                  className="text-[18px] leading-[26px] font-[400] text-[#FFFFFF]"
                  onClick={handleMenu}
                >
                  Menu
                </button>
                {isShowMenu && <PopupMenu closeMenu={closeMenu} />}
              </div>
              <div className="text-[#FFFFFF]">|</div>
              <button
                type="button"
                onClick={() => navigate("/login")}
                className="text-[18px] leading-[26px] font-[400] text-[#FFFFFF]"
              >
                로그인
              </button>
            </>
          )}
        </div>
      </div>
      <div className="flex flex-col h-full items-center justify-start space-y-[22px] pt-[12%]">
        <LANGSA_TEXT fill="#FFFFFF" width={250} height={75} />
        <div className="text-[16px] leading-[20px] font-[500] text-[#FFFFFF]">
          {/* 안녕하세요 Langsa입니다! Timbel의 새로운 AI 기반 검색 모드입니다 ✨ */}
          {mainText}
        </div>
        <div className="flex flex-row w-[670px] items-center justify-between">
          <div className="relative flex-row items-center justify-end">
            <IconGlasses
              fill="#000"
              width={24}
              height={24}
              className="absolute bottom-[13px] right-[2%] cursor-pointer"
              onClick={onSearch}
            />
            <IconClose
              fill="#959595"
              width={15}
              height={15}
              className={`absolute bottom-[18px] right-[8%] cursor-pointer ${
                text !== "" ? "block" : "hidden"
              }`}
              onClick={handleSearchClear}
            />
            <input
              type="text"
              placeholder="검색어를 입력하세요"
              className="w-[600px] h-[50px] rounded-[25px] indent-[18px]"
              value={text}
              onChange={handleSearch}
              onKeyUp={onEnterKeyPress}
            />
          </div>
          <LANGSA_LOGO width={50} height={50} />
        </div>
      </div>
    </div>
  );
}
