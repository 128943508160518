import { ReactComponent as IconMenuPlayground } from "../../assets/images/icons/icon-menu-playground.svg";
import { ReactComponent as IconMenuAIKMS } from "../../assets/images/icons/icon-menu-ai_kms.svg";
import { ReactComponent as IconMenuHome } from "../../assets/images/icons/icon-menu-home.svg";

const MenuList = [
  {
    icon: <IconMenuHome />,
    title: "Home",
    url: "/app/selection",
  },
  {
    icon: <IconMenuPlayground />,
    title: "Playground",
    url: "/playground",
  },
  {
    icon: <IconMenuAIKMS />,
    title: "AI KMS",
    url: "/kms/knowledge",
  },
  {
    icon: <IconMenuAIKMS />,
    title: "AI Builder",
    url: "/kms/knowledge",
  },
];

function PopupMenu({ closeMenu }) {
  return (
    <div className="relative">
      <div className="fixed z-[99] right-[22px]">
        <div className="flex w-full justify-center">
          <div className="w-0 h-0 border-l-[12px] border-r-[12px] border-b-[18px] border-l-transparent border-r-transparent border-b-[#FFFFFF]" />
        </div>
        <div className="w-[240px] h-full bg-[#FFFFFF] rounded-[10px]">
          <div className="px-[16px] py-[14px] space-y-[8px]">
            {MenuList.map((menu, idx) => {
              return (
                <div
                  key={idx}
                  className="flex flex-row z-[5] items-center space-x-[20px] hover:bg-[#F5F5F5] hover:rounded-[10px] cursor-pointer"
                >
                  <div className="w-[38px] h-[38px]">{menu.icon}</div>
                  <div className="">{menu.title}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div
        className="fixed z-[9] top-0 left-0 w-screen h-screen"
        onClick={closeMenu}
      />
    </div>
  );
}

export default PopupMenu;
