import { useCallback } from "react";
import { useFetch } from "../hooks/useFetch";

const useSession = () => {
  // 다산 숨김
  // const SERVER = "https://dev.langsa.ai";
  const SERVER = "https://120dasan.langsa.ai";

  const fetchService = useFetch();

  const getSession = useCallback(() => {
    return fetchService.get(`${SERVER}/api/users/current-data`);
  }, [fetchService]);

  const setSessionSetting = useCallback(
    (setting) => {
      return fetchService.post(`${SERVER}/api/users/current-data`, {
        body: {
          currentData: {
            setting,
          },
        },
      });
    },
    [fetchService],
  );

  const setSessionChatMsg = useCallback(
    (chatMsg) => {
      return fetchService.post(`${SERVER}/api/users/current-data`, {
        body: {
          currentData: {
            chatMsg,
          },
        },
      });
    },
    [fetchService],
  );

  return {
    getSession,
    setSessionSetting,
    setSessionChatMsg,
  };
};

export default useSession;
